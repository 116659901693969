import { Link, graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Shapefour from "../images/bannerElement/blog.svg"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import "../styles/banner.css"
import "../styles/blog.css"

const BlogPage = ({ data, pageContext }) => {
  const blogoverviewcontent = data.blogoverview
  const catlistcontent = data.catlist
  const autlistcontent = data.autlist
  const archiveyrcontent = data.archiveyr

  const [openFilter, setOpenFilter] = useState("")

  // Array of all news articles
  const blogsow = data.allWpPost.nodes

  // State for the list
  const [list, setList] = useState([...blogsow.slice(0, 9)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(blogsow.length > 9)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < blogsow.length
      const nextResults = isMore
        ? blogsow.slice(currentLength, currentLength + 9)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < blogsow.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <>
      {blogoverviewcontent.edges.map(({ node }) => (
        <Helmet>
          <title>{node.title} | Position2</title>
          <meta name="description" content={node.seo.metaDesc} />
          <body className={node.slug} />
        </Helmet>
      ))}
      <Layout>
        {/* <section id="Banner" className="no-cta">
          {blogoverviewcontent.edges.map(({ node }) => (
            <BannerComponent
              title={node.bannerSection.bannerTitle}
              dBanner={
                node.bannerSection.heroBannerImage.node.localFile.publicURL
              }
              mBanner={node.bannerSection.mobileBanner.node.localFile.publicURL}
              mAlt={node.bannerSection.mobileBanner.node.altText}
              dAlt={node.bannerSection.heroBannerImage.node.altText}
            />
          ))}
        </section> */}
        <div className="blogPage">
          <section id="Banner">
            <div class="container">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>Insights that ignite growth</h1>
                    <p>
                      Elevate your marketing game with our AI-driven expert
                      insights
                    </p>
                    <a className="button" href="/contact-us">
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="filter-menu">
            <div className="container">
              <div className="container-width">
                <div className="wrap">
                  <div className="filters-title">
                    <a
                      className="filter_expand filter-category"
                      onClick={() =>
                        setOpenFilter(
                          openFilter === "categories" ? "" : "categories"
                        )
                      }
                    >
                      Categories
                      <span
                        className={
                          openFilter === "categories" ? "minus" : "plus"
                        }
                      >
                        {openFilter === "categories" ? "-" : "+"}
                      </span>
                    </a>
                    <a
                      className="filter_expand filter-author"
                      onClick={() =>
                        setOpenFilter(openFilter === "authors" ? "" : "authors")
                      }
                    >
                      Author
                      <span
                        className={openFilter === "authors" ? "minus" : "plus"}
                      >
                        {openFilter === "authors" ? "-" : "+"}
                      </span>
                    </a>
                    <a
                      className="filter_expand filter-archive"
                      onClick={() =>
                        setOpenFilter(openFilter === "archive" ? "" : "archive")
                      }
                    >
                      Archive
                      <span
                        className={openFilter === "archive" ? "minus" : "plus"}
                      >
                        {openFilter === "archive" ? "-" : "+"}
                      </span>
                    </a>
                  </div>
                  <div className="filters-expands-list">
                    {openFilter === "categories" && (
                      <div className="fe-list categories-list">
                        <div className="category">
                          <ul>
                            {catlistcontent.nodes.map(({ name, uri }) => (
                              <li key={uri}>
                                <Link to={uri}>{name}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <span
                          className="close"
                          onClick={() => setOpenFilter("")}
                        ></span>
                      </div>
                    )}
                    {openFilter === "authors" && (
                      <div className="fe-list authors-list">
                        <ul>
                          {autlistcontent.nodes.map(({ name, uri, slug }) => (
                            <li className={slug} key={uri}>
                              <Link to={uri}>{name}</Link>
                            </li>
                          ))}
                        </ul>
                        <span
                          className="close"
                          onClick={() => setOpenFilter("")}
                        ></span>
                      </div>
                    )}
                    {openFilter === "archive" && (
                      <div className="fe-list archive-list">
                        <ul>
                          {archiveyrcontent.nodes.map(
                            ({ name, uri, count }) => (
                              <li key={uri}>
                                <Link to={uri}>
                                  {name}
                                  <span>({count})</span>
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                        <span
                          className="close"
                          onClick={() => setOpenFilter("")}
                        ></span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="blog-overview">
            <div className="container">
              <div className="container-width clearfix">
                {list.map(({ uri, blogSection, date, title, author }) => (
                  <a href={uri} className="each-blog">
                    <div className="wrap-img">
                      <img
                        src={
                          blogSection.blogFeaturedImage.node.localFile.publicURL
                        }
                        alt={blogSection.blogFeaturedImage.node.altText}
                      />
                    </div>
                    <div className="blog-wrap">
                      <p className="date">{date}</p>
                      <h2
                        className="btitle"
                        dangerouslySetInnerHTML={{ __html: title }}
                      ></h2>
                      <div className="author">
                        <span>By</span> {author.node.name}
                      </div>
                      <div className="blog-descp">
                        <span>Read More</span>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
              <div className="lm-wrap">
                {hasMore ? (
                  <button onClick={handleLoadMore}>Load more</button>
                ) : (
                  <p>No more results</p>
                )}
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>Grow Your Business In a Smarter Way</h2>
              <a className="button" href="/contact-us">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    blogoverview: allWpPage(filter: { id: { eq: "cG9zdDoxMzc2" } }) {
      edges {
        node {
          id
          title
          id
          slug
          bannerSection {
            bannerTitle
            heroBannerImage {
              node {
                altText
                localFile {
                  publicURL
                }
              }
            }
            mobileBanner {
              node {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
          seo {
            metaDesc
          }
          descriptionWithCtaBlock {
            descriptionSection
            ctaUrl
            ctaButtonText
          }
        }
      }
    }
    catlist: allWpCategory {
      nodes {
        uri
        name
      }
    }
    autlist: allWpUser {
      nodes {
        name
        uri
        slug
      }
    }
    archiveyr: allWpArchive(sort: { order: DESC, fields: name }) {
      nodes {
        name
        uri
        count
        slug
      }
    }
    allWpPost(sort: { order: DESC, fields: date }) {
      nodes {
        title
        uri
        date(formatString: "LL")
        blogSection {
          blogFeaturedImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
          topBannerImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
        author {
          node {
            name
            uri
            posts {
              nodes {
                title
                uri
                slug
              }
            }
          }
        }
        categories {
          nodes {
            uri
            slug
            name
            posts {
              nodes {
                slug
                title
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPage
